<template>
  <video
    class="module"
    :poster="poster"
    :autoplay="autoplay"
    :muted="silent"
    @canplay.once="start"
  >
    <source
      v-for="(value, key) in sources"
      :key="`source-${key}`"
      :src="key"
      :type="value"
    />
  </video>
</template>

<script>
export default {
  name: "v-video",
  props: {
    video: {
      type: String,
      required: true,
    },
    poster: String,
    media: String,
    autoplay: Boolean,
    required: Boolean,
  },
  computed: {
    // Google Chrome ignores media queries for source tag
    supported() {
      const exp = /Edge\/|iPad|iPhone|iPod|Android/;
      const blacklisted = exp.test(navigator.userAgent);
      return this.required && !blacklisted;
    },
    sources() {
      const sources = this.video.split("|") || [];
      const result = {};
      sources.map((src) => {
        const type = /\.webm(\?.*)?$/.exec(src) ? "video/webm" : "video/mp4";
        result[src] = type;
        return type;
      });
      return result;
    },
  },
  methods: {
    silent() {
      return this.muted || this.autoplay;
    },
    start() {
      // Only muted videos dynamically runs In Chromium
      if (this.autoplay) {
        const promise = this.$el.play();
        if (promise !== null) {
          promise.catch((e) => {
            // eslint-disable-next-line
            console.log(e.message);
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
// Disable Operas extra popups:
html > div {
  display: none;
}

video {
  max-width: 100%;
}
</style>
