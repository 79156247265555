<template>
  <div class="benefits__wrapper" ref="videoPlayers">
    <div class="benefit" v-animate-onscroll="'animated backIn delay-05'">
      <div class="benefit__img">
        <v-video
          id="videoPlayer"
          required
          muted
          poster="/video/posters/icons/01.svg"
          video="/video/icons/01.webm"
        />
      </div>
      <div class="benefit__title h4">Гибкий подход</div>
      <p class="benefit__text p-4">
        Индивидуальные условия, учитывающие вашу ситуацию с&nbsp;дебиторами
        и&nbsp;текущими кредитами.
      </p>
    </div>
    <div class="benefit" v-animate-onscroll="'animated backIn delay-10'">
      <div class="benefit__img">
        <v-video
          required
          muted
          poster="/video/posters/icons/02.svg"
          video="/video/icons/02.webm"
        />
      </div>
      <div class="benefit__title h4">Деньги быстро</div>
      <p class="benefit__text p-4">
        Факторинговое финансирование можно получить в&nbsp;день поставки. Без
        лишних действий и&nbsp;затягивания процессов.
      </p>
    </div>
    <div class="benefit" v-animate-onscroll="'animated backIn delay-15'">
      <div class="benefit__img">
        <v-video
          required
          muted
          poster="/video/posters/icons/03.svg"
          video="/video/icons/03.webm"
        />
      </div>
      <div class="benefit__title h4">Можно доверять</div>
      <p class="benefit__text p-4">
        Большой опыт работы в&nbsp;сфере электронного факторинга. Являемся
        одними из&nbsp;лидеров рынка.
      </p>
    </div>
    <div class="benefit" v-animate-onscroll="'animated backIn delay-20'">
      <div class="benefit__img">
        <v-video
          required
          muted
          poster="/video/posters/icons/04.svg"
          video="/video/icons/04.webm"
        />
      </div>
      <div class="benefit__title h4">Минимум трудозатрат</div>
      <p class="benefit__text p-4">
        Сервис сокращает объём необходимых действий для заключения договора
        с&nbsp;фактором.
      </p>
    </div>
  </div>
</template>

<script>
import video from "@/components/Video";

export default {
  name: "v-benefits",
  components: {
    "v-video": video,
  },
  data() {
    return {
      videoCount: 0,
    };
  },
  mounted() {
    let wrapper = document.querySelector(".benefits__wrapper");
    let videos = wrapper.querySelectorAll("video");
    setTimeout(() => {
      setInterval(() => {
        if (this.isInViewport(this.$el)) {
          this.startVideo(videos, this.videoCount);
        }
      }, 2125);
    }, 250);
  },
  methods: {
    startVideo(el, i) {
      el[i].play();
      this.videoCount++;

      if (this.videoCount == el.length) this.videoCount = 0;
    },
    isInViewport(el) {
      let bounding = el.getBoundingClientRect();

      return (
        bounding.top >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.benefits {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: $space-56;
    text-align: center;

    @include mobile {
      margin-bottom: $space-32;
      text-align: left;
    }
  }
}

.benefit {
  width: calc(100% / 4 - 40px);

  @include tablet {
    width: calc(100% / 2 - 40px);
    margin-bottom: $space-32;
  }

  @include mobile {
    width: 100%;
    margin-bottom: $space-32;
  }

  &__img {
  }

  &__title {
    margin: $space-24 0 $space-16;
  }

  &__text {
  }
}
</style>
